import { render, staticRenderFns } from "./HeaderContactPage.vue?vue&type=template&id=7545bfbc&"
import script from "./HeaderContactPage.vue?vue&type=script&lang=js&"
export * from "./HeaderContactPage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CampusDropDownMenuHeader: require('/home/etienne/nuxt/AM-frontend/components/CampusDropDownMenuHeader.vue').default})
