

export default {
    head() {
        return {
            title: `Vous êtes perdu - ICFA`,
            meta: [
                {
                    hid: 'og-title',
                    property: 'og:title',
                    content: 'ICFA - Vous êtes perdu',
                },
                {
                    hid: 'og-description',
                    property: 'og:description',
                    content: `Vous êtes perdu ?`,
                }
            ]
        };
    },
    data() {
        const strapiBaseUri = process.env.strapiBaseUri


        return {
            strapiBaseUri,
        }
    },


    mounted() {
        this.isMounted = true;
    },
    methods: {

    },
    async asyncData({ params, store }) {
        let instances = store.state.instances;


        return {
            params, instances
        }
    },
}



