

export default {
    props: ['instances'],

    data() {
        return {
            ref: null,
            showListCampus: false,
            openNavBar: false,
        };
    },
    methods: {
        openCampusesList(event) {
            this.showListCampus = !this.showListCampus;
        },
        close(e) {
            if (!this.$el.contains(e.target)) {
                this.showListDiploma = false
                this.showListCampus = false
            }
        }
    },
    mounted() {
        document.addEventListener('click', this.close)
    },
    beforeDestroy() {
        document.removeEventListener('click', this.close)
    }
}
