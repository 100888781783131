
 export default {
    props: ['instance'],
    computed: {
       styles () {
          let r, g, b;
          r = this.instance?.attributes?.couleurCampusCardRGB?.substring(0,3);
          g = this.instance?.attributes?.couleurCampusCardRGB?.substring(3,6);
          b = this.instance?.attributes?.couleurCampusCardRGB?.substring(6,9);
 
          return {
         '--text-color': '#aaaaaa',
         '--text-color1r': Number(r)+10,
         '--text-color1g': Number(g)-55,
         '--text-color1b': Number(b)+30,
         '--text-color2r': Number(r)+10,
         '--text-color2g': Number(g)+10,
         '--text-color2b': Number(b)+25,
         '--text-color3r': Number(r)+40,
         '--text-color3g': Number(g)-50,
         '--text-color3b': Number(b)-50,
         '--text-color4r': Number(r)-30,
         '--text-color4g': Number(g)+30,
         '--text-color4b': Number(b)+55
       }} 
    }
 
 }
 